import Vue from 'vue'
import VueRouter from 'vue-router'
// import details from '../views/News/details/detailes.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home/index.vue')
  },
  {
    path: '/About',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About/index.vue')
  },
  {
    path: '/Potenciator',
    name: 'Potenciator',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Potenciator/index.vue')
  },
  {
    path: '/Product',
    name: 'Product',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Product/index.vue')
  },
  {
    path: '/News/detailes',
    name: '/News/detailes',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/News/detailes.vue')
  },
  {
    path: '/News',
    name: 'News',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/News/index.vue')
  },

  {
    path: '/Phone',
    name: 'Phone',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Phone/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
